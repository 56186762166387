import { gql } from '@apollo/client';
import genericFields from './genericFields';

const agencyItemsFragmentQuery = gql`
  fragment agencyItemFields on Item {
    ${genericFields}
    photos(limit: 1) {
      thumbnail
    }
  }
`;

export const agenciesRowQuery = gql`
  query AgenciesRowQuery {
    agencies(limit: 8, sort: RANDOM) {
      items(limit: 1, sort: RANDOM) {
        ...agencyItemFields
      }
    }
  }
  ${agencyItemsFragmentQuery}
`;

export const agenciesQuery = gql`
  query AgenciesQuery {
    agencies(sort: RANDOM) {
      id
      name
      logoUrl
      description
      itemsCount
      path
    }
  }
`;

export function getAgencyDataQuery(slug) {
  const name = `GetAgencyDataQuery_${slug}`.replace(/[^_0-9A-Za-z]/g, '');
  return gql`
    query ${name}($slug: String!) {
      agency(slug: $slug) {
        id
        name
        description
        viewsCount
        contactAddress
        schedule
        phonesMasked
        latitude
        longitude
        logoUrl
        coverUrl
        itemsCount
        path
        itemCategories {
          id
          pluralName
        }
      }
      categories {
        id
        title
        children {
          id
          title
        }
      }
    }
  `;
}

export function agencyFilter(filter, limit) {
  const filterString = JSON.stringify(filter)
    .replace(/[^\w\s]/g, '')
    .replace(/\s/g, '');
  const name = `GetAgencyDataQuery_${filterString}_${limit}`;
  return gql`
    query ${name}(
      $first: Int
      $filter: ItemFilter
      $cursor: String
    ) {
      itemsConnection(
        first: $first
        after: $cursor
        filter: $filter
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            vipped
            leased
            path
            photosCount
            paidDaily
            leased
            hasBillOfSale
            hasMortgage
            hasRepair
            featured
            vipped
            updatedAt
            photos(limit: 4) {
              thumbnail
              f460x345
              large
            }
            price {
              value
              currency
            }
            city {
              name
            }
            company {
              id
              name
              targetType
            }
            area {
              value
              units
            }
            location {
              id
              name
              fullName
            }
          }
        }
      }
    }
  `;
}
